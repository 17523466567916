import React, {
  useEffect,
  useState,
  useRef,
  ReactNode,
  UIEventHandler,
  useContext,
} from 'react'
import css from './NoteBuilder.module.css'
import Button from '../../buttons/Button/Button'
import TitleInput from '../../inputs/TitleInput/TitleInput'
import { X as IconClose, Plus } from 'react-feather'
import SegmentedInput from '../../inputs/SegmentedInput/SegmentedInput'
import { IconSquare } from '../../atoms/Icons/Icons'
import ButtonIcon from '../../buttons/ButtonIcon/ButtonIcon'

import { Controller, FormProvider } from 'react-hook-form'
import Confirm from '../../modals/Confirm'
import CalendarContext from '../../../context/CalendarContext'
import useCycleBuilder from '../../../hooks/cycles/useCycleBuilder'
import { Cycle } from '../../../types/cycles'
import TextAreaInput from '../../inputs/TextAreaInput/TextAreaInput'
import DateRangeInput from '../../inputs/DateRangeInput/DateRangeInput'
import { getRandomCycleName } from '../../../utilities/getRandomCycleName'
import useCyclePublisher from '../../../hooks/cycles/useCyclePublisher'
import { Workout } from '../../../types/workouts'
import { CalendarEvent } from '../../../types/calendarEvents'
import useNoteBuilder from '../../../hooks/notes/useNoteBuilder'
import NoteTypeSelect from '../../inputs/NoteTypeSelect/NoteTypeSelect'
import ToggleInput from '../../inputs/ToggleInput/ToggleInput'
import DatePicker from '../../inputs/DatePicker/DatePicker'
import { DateTime } from 'luxon'
import { AppContext } from '../../../context/AppContext'

type Props = {
  activeDate: string
  noteToEdit: CalendarEvent.Note | null
  newNoteType?: 'note' | 'staff-note' | 'pinned-note'
  onDismiss: () => void
}

const NoteBuilder = ({
  activeDate,
  noteToEdit,
  newNoteType = 'note',
  onDismiss,
}: Props) => {
  const { formMethods, isDirty, isValid, publishNote } = useNoteBuilder({
    noteToEdit,
    onSuccess: onDismiss,
    newNoteType,
    activeDate,
  })

  const [scrollShadowIsVisible, setScrollShadowIsVisible] = useState(false)

  const { currentSpace } = useContext(AppContext)
  const advancedNotesEnabled =
    !!currentSpace?.subscription.enabled_features.advanced_notes

  const handleBuilderScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = (e.target as HTMLElement).scrollTop
    if (!scrollShadowIsVisible && scrollTop > 10) setScrollShadowIsVisible(true)
    else if (scrollShadowIsVisible && scrollTop <= 10)
      setScrollShadowIsVisible(false)
  }

  const [confirmation, setConfirmation] = useState<{
    title: string
    text: string
    what: string
  } | null>(null)

  const {
    pendingActiveDate,
    preventActiveDateChange,
    proceedToPendingDate,
    setPreventActiveDateChange,
  } = useContext(CalendarContext)

  useEffect(() => {
    if (pendingActiveDate && preventActiveDateChange) {
      setConfirmation({
        title: 'Proceed without saving?',
        text: 'All the changes will be lost, like tears in the rain.',
        what: 'switch-date',
      })
    }
  }, [pendingActiveDate])

  const handleDeleteConfirmation = () => {
    if (noteToEdit) {
      onDismiss()
      // deleteCycle(noteToEdit)          <---------------------------
    }
    setConfirmation(null)
  }

  const handleCloseConfirmation = () => {
    onDismiss()
    setConfirmation(null)
    setPreventActiveDateChange(false)
  }

  useEffect(() => {
    if (!noteToEdit) formMethods.setFocus('details.title')
  }, [])

  const isPinned = formMethods.watch('pinOptions.isPinned')
  const pinDates = formMethods.watch('pinOptions.pinDates')

  const formHasErrors = Object.keys(formMethods.formState.errors).length > 0

  const dateTile = isPinned
    ? pinDates
      ? `Pinned, ${DateTime.fromISO(pinDates.startDateISO).toFormat(
          'LLL d'
        )} – ${DateTime.fromISO(pinDates.endDateISO).toFormat('LLL d')}`
      : 'Pinned'
    : DateTime.fromISO(activeDate).toFormat('LLL d, yyyy')

  const noteCategory = formMethods.watch('details.category')

  return (
    <FormProvider {...formMethods}>
      <Confirm
        isVisible={!!confirmation}
        title={confirmation ? confirmation.title : ''}
        text={confirmation ? confirmation.text : ''}
        buttonLabels={
          confirmation && confirmation.what === 'delete'
            ? { primary: 'Delete', secondary: 'Go Back' }
            : { primary: 'Proceed', secondary: 'Go Back' }
        }
        onDismiss={() => setConfirmation(null)}
        onConfirm={() => {
          if (confirmation)
            switch (confirmation.what) {
              case 'delete':
                return handleDeleteConfirmation()
              case 'close':
                return handleCloseConfirmation()
              case 'switch-date': {
                setPreventActiveDateChange(false)
                proceedToPendingDate()
                handleCloseConfirmation()
              }
            }
        }}
        isDestructive={confirmation?.what === 'delete'}
      />
      <header className={css['header']}>
        <div className={`${css['subheader']} ${'light'}`}>{dateTile}</div>
        <TitleInput
          editWorkoutMode={!!noteToEdit}
          defaultTitle={noteCategory ? `${noteCategory} Title` : 'Title'}
          placeholder={noteCategory ? `${noteCategory} Title` : 'Title'}
          isBenchmarkEditMode={false}
          isRequired={false}
          fieldName="details.title"
          editTitlePlaceholder={`Edit ${noteCategory}`}
          newTitlePlaceholder={`New ${noteCategory}`}
        />
        <div className={css['close-icon']}>
          <ButtonIcon
            icon={<IconClose />}
            className={css['close-icon']}
            onClick={() =>
              isDirty
                ? setConfirmation({
                    title: 'Proceed without saving?',
                    text: 'All the changes will be lost, like tears in the rain.',
                    what: 'close',
                  })
                : onDismiss()
            }
            type={'light'}
            style={{ margin: '-4px 0 0 0' }}
          />
        </div>

        <div
          className={`${css['scroll-shadow']} ${
            scrollShadowIsVisible && css['visible']
          }`}
        />
      </header>

      <div id={css['workout-builder']} onScroll={handleBuilderScroll}>
        <div className={css['top-items']} style={{ flex: 1, marginTop: '2px' }}>
          <div
            style={{
              paddingTop: '24px',
              borderTop: '1px solid var(--divider)',
              borderBottom: '1px solid var(--divider)',
              display: 'flex',
              flexDirection: 'column',
              flex: '1 0',
              paddingBottom: '16px',
            }}
          >
            <h3 style={{ marginTop: '0' }}>Details</h3>
            {advancedNotesEnabled && (
              <>
                <label>Category</label>
                <Controller
                  name="details.category"
                  control={formMethods.control}
                  render={({ field, fieldState }) => (
                    <NoteTypeSelect
                      name="details.category"
                      value={field.value}
                      onChange={field.onChange}
                      style={{ margin: '0 0 16px' }}
                      fieldState={fieldState}
                    />
                  )}
                />{' '}
              </>
            )}
            <TextAreaInput
              label="Text"
              name="details.content"
              formMethods={formMethods}
              isRequired={'Please add text'}
              characterLimit={10000}
              style={{ minHeight: '200px', flex: '1' }}
            />
            <TextAreaInput
              label="Preview"
              name="details.preview"
              placeholder="Leave blank to use the first 140 characters of the actual note"
              formMethods={formMethods}
              isRequired={false}
              characterLimit={140}
              style={{ height: '100px', flexGrow: '0' }}
            />
          </div>
          {advancedNotesEnabled && (
            <div>
              <h3 style={{ marginBottom: '24px' }}>Options</h3>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <ToggleInput
                  label="Allow comments and likes"
                  isActive={formMethods.watch('details.allowSocials')}
                  onToggle={() => {
                    const current = formMethods.getValues(
                      'details.allowSocials'
                    )
                    formMethods.setValue('details.allowSocials', !current)
                  }}
                />
                <div>
                  <ToggleInput
                    label="Pin note"
                    isActive={isPinned}
                    onToggle={() => {
                      formMethods.setValue('pinOptions.isPinned', !isPinned)
                    }}
                  />
                  <div
                    style={{
                      paddingLeft: '56px',
                      height: isPinned ? 'auto' : '0',
                      overflow: 'hidden',
                    }}
                  >
                    <DateRangeInput
                      name="pinOptions.pinDates"
                      label={'Pin Dates'}
                      formMethods={formMethods}
                      mode="iso"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={css['bottom-items']}>
          {!isValid ? (
            <p
              className="caption"
              style={{
                textAlign: 'center',
                marginBottom: '4px',
                color: formHasErrors ? 'var(--accent-red)' : undefined,
              }}
            >
              {formHasErrors
                ? 'Please correct all errors'
                : 'Please fill out required fields'}
            </p>
          ) : (
            <p
              className="caption"
              style={{ textAlign: 'center', marginBottom: '4px' }}
            >
              &nbsp;
            </p>
          )}
          <Button
            onClick={publishNote}
            disabled={!isValid}
            className={!isValid ? 'disabled' : undefined}
          >
            {noteToEdit ? `Save ${noteCategory}` : `Publish ${noteCategory}`}
          </Button>
          {noteToEdit ? (
            <Button
              onClick={() =>
                setConfirmation({
                  title: 'Delete Note?',
                  text: 'This cannot be undone.',
                  what: 'delete',
                })
              }
              type="tertiary-destructive"
              className="tertiary-destructive"
              style={{ marginTop: '16px' }}
            >
              Delete
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
    </FormProvider>
  )
}

export default NoteBuilder
