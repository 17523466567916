import React, { CSSProperties } from 'react'
import { UseFormReturn } from 'react-hook-form'

type Props = {
  name: string
  label?: string
  placeholder?: string
  characterLimit?: number
  formMethods: UseFormReturn<any>
  style?: CSSProperties
  isRequired?: boolean | string
}

const TextAreaInput = ({
  style,
  formMethods,
  placeholder,
  name,
  label,
  characterLimit,
  isRequired,
}: Props) => {
  const charLength = formMethods.watch(name).length
  const isError = formMethods.getFieldState(name).error
  const formState = formMethods.formState

  const errorMessage = formMethods.getFieldState(name).error?.message ?? null

  const textTooLong = characterLimit && charLength > characterLimit

  return (
    <>
      {!!label && (
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <label>
            {label}
            {!isRequired && <span> (optional)</span>}
          </label>
          {!!characterLimit && (
            <span style={{ font: 'var(--small-text)' }}>
              <span
                style={
                  textTooLong
                    ? { color: 'var(--accent-red)', fontWeight: '600' }
                    : {}
                }
              >
                {charLength}
              </span>
              /{characterLimit}
            </span>
          )}
        </div>
      )}
      <textarea
        style={style}
        className={formMethods.getFieldState(name).error ? 'error' : ''}
        placeholder={placeholder}
        //maxLength={characterLimit}
        {...formMethods.register(name, {
          required: isRequired,
          validate: (value) => {
            if (characterLimit && value.length > characterLimit) {
              return `Text must be less than ${characterLimit} characters`
            }
          },
        })}
      />
      {isError ? (
        <div
          style={{
            marginTop: '8px',
            font: 'var(--h5)',
            color: 'var(--accent-red)',
          }}
        >
          {errorMessage}
        </div>
      ) : (
        <div
          style={{
            marginTop: '8px',
            font: 'var(--h5)',
            color: 'var(--accent-red)',
          }}
        >
          &nbsp;
        </div>
      )}
    </>
  )
}

export default TextAreaInput
